<template>
    <div>
      <b-container class="pt-4 pb-4">
        <b-card class="text-center">
          <template v-if="order.payment_status == 'failed'">
            <h1>Betaling mislukt</h1>
            <p>Het is helaas niet gelukt om de betaling te voltooien. Betaal je tickets binnen 1 uur, anders worden deze vrijgegeven.</p>
            <b-button :to="'/'" variant="primary">Terug naar het overzicht</b-button>
          </template>
          <template v-if="order.payment_status == 'expires'">
            <h1>Betaling verlopen</h1>
            <p>Het is helaas niet gelukt om de betaling te voltooien. Betaal je tickets binnen 1 uur, anders worden deze vrijgegeven.</p>
            <b-button :to="'/'" variant="primary">Terug naar het overzicht</b-button>
          </template>
          <template v-if="order.payment_status == 'open' || order.payment_status == 'pending'">
            <h1>Wachten op betaling</h1>
            <p>Wij wachten momenteel op je betaling. Voltooi de betaling en verver dit scherm.</p>
            <b-button :to="'/'" variant="primary">Terug naar het overzicht</b-button>
          </template>
          <template v-if="order.payment_status == 'canceled'">
            <h1>Bestelling geannuleerd</h1>
            <p>Je hebt de bestelling geannuleerd. Wil je nieuwe tickets bestellen? Klik dan op de volgende link.</p>
            <b-button :to="'/'" variant="primary">Terug naar het overzicht</b-button>
          </template>
          <template v-if="order.payment_status == 'paid'">
            <h1>Bedankt voor je bestelling</h1>
            <p>Wij hebben je bestelling ontvangen en gaan direct aan de slag om je tickets te maken. Je ontvangt de tickets binnen enkele minuten in je mailbox.</p>
            <b-button to="/account" variant="primary">Ga naar je account</b-button>
          </template>
        </b-card>
      </b-container>
    </div>
</template>
    
<script>
    export default {
      metaInfo: {
        title: 'Tickets',
      },
      components: {},
      data() {
          return {
            order: {}
          }
      },
      computed: {},
      methods: {
        getOrderStatus: function() {
            this.$axios.get('https://api.tixgo.nl/order/' + this.$route.params.order_id)
            .then( response => {
                if (response.data) {
                    this.order = response.data;
                }
            }
            ).catch((reason) => {
                console.log(reason);
            });
        }
      },
      created() {
        this.getOrderStatus();
      }
    }
</script>